<template >
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.deleted_products')">
            <CustomTable  :fields="fields" api-url="/api/client/v1/archived/products">
                <template #actions="{item}">
                    <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="viewDetails(item)">
                        {{$t('general.details')}}
                    </BButton>
                </template>
            </CustomTable>
        </b-card>

        <ProductDetails ref="productDetails"></ProductDetails>
    </b-overlay>
</template>

<script>
    import {BCard, BButton, BOverlay} from 'bootstrap-vue'
    import ProductDetails from '@/views/ArchivedOpenTabs/components/ProductDetails.vue'
    import * as Sentry from '@sentry/vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components:{CustomTable, ProductDetails, BCard, BOverlay, BButton},
        data() {
            return {
                fields: [
                    {
                        key: 'deleted_timestamp',
                        label: this.$t('table_fields.deleted'),
                        filter: true,
                        filter_type: 'date',
                        formatter: Formaters.formatDate,
                        permissions: true
                    },
                    {
                        key: 'employee.name',
                        label: this.$t('table_fields.employee'),
                        filter: true,
                        filter_type: 'select',
                        filterParams: this.employees,
                        filterParamsName: 'name',
                        filterKey: 'employee.id', // po cem filtrira, default = key
                        reduce: ele => ele.id,
                        permissions: true
                    },
                    {
                        key: 'products',
                        label: this.$t('table_fields.number_of_deleted_products'),
                        filter: false,
                        formatter: Formaters.formatNumberOfProducts,
                        permissions: true
                    },
                    {
                        key: 'business_unit.name',
                        label: this.$t('table_fields.business_unit'),
                        filter: true,
                        filter_type: 'select',
                        filterParams: this.businessUnits,
                        filterParamsName: 'name',
                        filterKey: 'business_unit.id',
                        reduce: ele => ele.id,
                        permissions: true
                    },
                    {
                        key: 'cash_register.cash_register_id',
                        label: this.$t('table_fields.cash_register'),
                        filter: true,
                        filter_type: 'select',
                        filterParams: this.cashRegisters,
                        filterParamsName: 'cash_register_id',
                        filterKey: 'cash_register.id',
                        reduce: ele => ele.id,
                        permissions: true
                    },
                    {
                        key: 'total_price',
                        label: this.$t('table_fields.total_price'),
                        filter: false,
                        formatter: Formaters.formatPrice,
                        filterKey: 'total_price.w',
                        filterFormatter: Formaters.formatNumberForFilter,
                        permissions: true
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ],
                showLoader: true,
                businessUnits: [],
                employees: [],
                cashRegisters: [],
                arrayLoad: false,
                Formaters
            }
        },
        methods:{
            async loadData() {
                try {
                    this.showLoader = true

                    const res1 = this.$http.get('/api/client/v1/business_units/')
                    const res2 = this.$http.get('/api/client/v1/cash_registers/?getAll=true')
                    const res3 = this.$http.get('/api/client/v1/employees/')

                    const responses = await Promise.all([res1, res2, res3])

                    this.businessUnits =  responses[0].data ?? []
                    this.cashRegisters = responses[1].data.items ?? []
                    this.employees = responses[2].data ?? []

                    const indexBusinessUnits = this.fields.findIndex(ele => ele.key === 'business_unit.name')
                    const indexCashRegisters = this.fields.findIndex(ele => ele.key === 'cash_register.cash_register_id')
                    const indexEmployees = this.fields.findIndex(ele => ele.key === 'employee.name')

                    if (indexBusinessUnits >= 0) {
                        this.fields[indexBusinessUnits].filterParams = this.businessUnits
                    }
                    if (indexCashRegisters >= 0) {
                        this.fields[indexCashRegisters].filterParams = this.cashRegisters
                    }
                    if (indexEmployees >= 0) {
                        this.fields[indexEmployees].filterParams = this.employees
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                    this.arrayLoad = true
                }
            },
            viewDetails(data) {
                this.$refs.productDetails.open(data)
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>